import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Button from "@bit/azheng.joshua-tree.button"
import classnames from "classnames"
import Stars from "./Stars"

const AllSocialReviews = ({
  language,
  heading,
  buttonHref,
  buttonText,
  isColumn,
  colorBack,
  animation
}) => {
  const animate = animation ? "fade-up" : ""

  const wrapperClasses = classnames({
    "home-section": heading,
    "ratings-review top-down": true,
    "color-back": colorBack
  })

  const data = useStaticQuery(graphql`
    query SocialQuery {
      locations: allLocationsJson(sort: { fields: locationData___order }) {
        nodes {
          locationData {
            gmbId
            facebookId
            cityName
            locationName
            order
            gmbOverride
          }
        }
      }
      facebookSocials: allSocialJson(
        sort: { fields: data___totalReviews, order: DESC }
        filter: { data: { platform: { eq: "facebook" } } }
      ) {
        nodes {
          data {
            platformId
            rating
            totalReviews
            url
            platform
          }
        }
      }
      gmbSocials: allSocialJson(
        sort: { fields: data___totalReviews, order: DESC }
        filter: { data: { platform: { eq: "googleMyBusiness" } } }
      ) {
        nodes {
          data {
            platformId
            rating
            totalReviews
            url
            platform
          }
        }
      }
    }
  `)

  const locations = data.locations.nodes

  let googleReviews = []
  const googles = data.gmbSocials.nodes

  googleReviews = matchSocialsWithLocations(locations, googles, "google")
  // facebookReviews = matchSocialsWithLocations(locations, facebooks, "facebook", false);
  function matchSocialsWithLocations(
    locations,
    platformArr,
    platform,
    useCityName = true
  ) {
    const reviews = []
    const map = {}

    locations.forEach(location => {
      let reviewObj = {}

      if (platform === "google" && map[location.locationData.gmbId]) {
        return
      }
      map[location.locationData.gmbId] = platform

      if (platform === "facebook" && map[location.locationData.facebookId]) {
        return
      }
      map[location.locationData.facebookId] = platform

      for (let i = 0; i < platformArr.length; i++) {
        if (Math.round(platformArr[i].data.rating) < 3) return

        if (
          platformArr[i].data.platformId === location.locationData.facebookId ||
          platformArr[i].data.platformId === location.locationData.gmbId
        ) {
          reviewObj = { ...platformArr[i].data }
        }
      }

      if (useCityName) {
        reviewObj.name = location.locationData.locationName
      }

      // Override the map google link, to a different gmb link
      if (platform === "google" && location.locationData.gmbOverride) {
        reviewObj.url = location.locationData.gmbOverride
      }

      reviewObj.order = location.locationData.order

      reviews.push(reviewObj)
    })

    return reviews
  }

  googleReviews.sort((a, b) => (a.order > b.order ? 1 : -1))
  let consolidated = googleReviews
  consolidated = consolidated.filter(rev => rev.rating || rev.platform)
  consolidated = [googleReviews[0]]

  const gridSize = `reviews-${consolidated.length}`

  const reviewsContainer = classnames({
    "all-reviews-container-flex": isColumn,
    "all-reviews-container-grid": !isColumn,
    [gridSize]: !isColumn
  })

  // add AOS animation attributes if using animation
  // otherwise will make links not clickable
  const attrs = {}
  if (animation) {
    ;(attrs["data-aos"] = animate), (attrs["data-aos-duration"] = 1200)
  }

  return (
    <section className={wrapperClasses}>
      <div className="container" {...attrs}>
        {heading && (
          <div className="columns">
            <div className="column is-2" />
            <div className="column is-20">
              <h2 className="has-text-centered h3-style">{heading}</h2>
            </div>
            <div className="column is-2" />
          </div>
        )}
        <div
          className="rating-reviews home-reviews columns is-centered iconless big-rating reviews-width"
          style={{ marginLeft: "auto", marginRight: "auto" }}>
          <div
            className={reviewsContainer}
            style={{ flexDirection: isColumn ? "column" : "row" }}>
            {consolidated.length &&
              consolidated.map((review, i) => (
                <div key={i} className="featured-social">
                  <div>
                    {!review.name && isColumn ? (
                      <></>
                    ) : (
                      <span
                        role="presentation"
                        className="reviews-city"
                        style={{
                          fontSize: "0.875rem",
                          lineHeight: "1.71",
                          color: "#6d6e6f",
                          display: "block",
                          marginBottom: review.name ? "-4px" : "20px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat"
                        }}>
                        {!review.name
                          ? ""
                          : review.name && language === "es"
                          ? `Oficina de ${review.name}`
                          : `${review.name}`}
                      </span>
                    )}
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      className="link-wrapper"
                      href={review.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <h3>
                        {review.platform === "googleMyBusiness"
                          ? "Google"
                          : "Facebook"}
                      </h3>
                      <div className="total-reviews">
                        ({review.totalReviews})
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                      rel="noreferrer">
                      <div className="review-icons">
                        <Stars rating={review.rating} />
                      </div>
                    </a>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                      rel="noreferrer">
                      <span className="reviews-number">
                        {review.rating === 5 ? "5.0" : review.rating}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {buttonHref && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button contained buttonText={buttonText} href={buttonHref} />
          </div>
        </div>
      )}
    </section>
  )
}

export default AllSocialReviews
